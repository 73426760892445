import { configureStore } from "@reduxjs/toolkit";
import corporateSalesReducer from "./corporateSalesSlice";
import purchaseReportReducer from "./purchaseReportSlice";
import b2bSalesReducer from "./b2bSalesSlice";
import b2cSalesReducer from "./b2cSalesSlice"

const store = configureStore({
  reducer: {
    corporateSales: corporateSalesReducer,
    purchaseReport: purchaseReportReducer,
    b2bSales: b2bSalesReducer,
    b2cSales: b2cSalesReducer,
  }
});

export default store;
