import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Spin, DatePicker, Button, Select } from "antd";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import Navbar from "../components/navbar";
import {fetchGstSalesReport} from "../api/api"
import { useSelector, useDispatch } from "react-redux";
import { setDateRange } from "../redux/b2bSalesSlice";

const { RangePicker } = DatePicker;
const { Option } = Select;

// const roundToTwo = (num) => Number(Math.round(num * 100) / 100).toFixed(2);
function roundToTwo(num) {
  const rounded = Math.round(num * 100) / 100; 
  return Math.abs(rounded) < 0.99 ? 0 : rounded;
}

const GstSalesReport = () => {
  const dispatch = useDispatch();
  const reduxDateRange = useSelector((state) => state.b2bSales.dateRange);
  const token = localStorage.getItem("token");
  const isLoggedIn = token ? true : false;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([null, null]);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = async (startDate, endDate) => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? dayjs(startDate).format("YYYY-MM-DD")
        : "";
      const formattedEndDate = endDate
        ? dayjs(endDate).format("YYYY-MM-DD")
        : "";

      const response = await fetchGstSalesReport(
        formattedStartDate,
        formattedEndDate
      );

      const groupedData = response?.data.reduce((acc, item) => {
     
        if (item.tax_number) {
          const invoice_no = item.invoice_no;

          if (!acc[invoice_no]) {
            acc[invoice_no] = {
              ...item,
              total_amount: 0,
              taxable_amount_0: 0,
              taxable_amount_18: 0,
              taxable_amount_12: 0,
              taxable_amount_5: 0,
              igst_18: 0,
              igst_12: 0,
              igst_5: 0,
              cgst_9: 0,
              sgst_9: 0,
              cgst_6: 0,
              sgst_6: 0,
              cgst_2_5: 0,
              sgst_2_5: 0,
              transaction_date: dayjs(item.transaction_date).format(
                "DD-MM-YYYY"
              ),
              customer: item.customer || "Unknown",
              gstin: item.tax_number || "Not Provided",
            };
          }

          const taxableAmount =
            parseFloat(item.unit_price) * parseFloat(item.sell_qty);
          const taxPercent = parseFloat(item.tax_percent);
          const taxAmount = taxableAmount * (taxPercent / 100);

          const isIGST = item.tax_name?.toLowerCase().includes("igst");

          if (taxPercent === 18) {
            acc[invoice_no].taxable_amount_0 += taxAmount + taxableAmount || 0;
            if (isIGST) {
              acc[invoice_no].igst_18 += taxAmount || 0;
            } else {
              acc[invoice_no].taxable_amount_18 += taxableAmount || 0;
              acc[invoice_no].cgst_9 += taxAmount / 2 || 0;
              acc[invoice_no].sgst_9 += taxAmount / 2 || 0;
            }
          } else if (taxPercent === 12) {
            acc[invoice_no].taxable_amount_0 += taxAmount + taxableAmount || 0;
            if (isIGST) {
              acc[invoice_no].igst_12 += taxAmount || 0;
            } else {
              acc[invoice_no].taxable_amount_12 += taxableAmount || 0;
              acc[invoice_no].cgst_6 += taxAmount / 2 || 0;
              acc[invoice_no].sgst_6 += taxAmount / 2 || 0;
            }
          } else if (taxPercent === 5) {
            acc[invoice_no].taxable_amount_0 += taxAmount + taxableAmount || 0;
            if (isIGST) {
              acc[invoice_no].igst_5 += taxAmount || 0;
            } else {
              acc[invoice_no].taxable_amount_5 += taxableAmount || 0;
              acc[invoice_no].cgst_2_5 += taxAmount / 2 || 0;
              acc[invoice_no].sgst_2_5 += taxAmount / 2 || 0;
            }
          }

          acc[invoice_no].total_amount += parseFloat(item.line_total) || 0;
        }
     

        return acc;
      }, {});

      for (const invoice_no in groupedData) {
        groupedData[invoice_no].total_amount +=  parseFloat(groupedData[invoice_no].shipping_charges) || 0
        groupedData[invoice_no].total_amount -=   groupedData[invoice_no].discount_amount || 0
        groupedData[invoice_no].taxable_amount_0 = groupedData[invoice_no].total_amount - groupedData[invoice_no].taxable_amount_0 || 0;
      }

      const groupedArray = Object.values(groupedData).map((item) => ({
        key: item.invoice_no,
        invoice_no: item.invoice_no,
        transaction_date: item.transaction_date,
        customer: item.customer,
        gstin: item.gstin,
        total_amount: roundToTwo(item.total_amount),
        taxable_amount_0: roundToTwo(item.taxable_amount_0),
        taxable_amount_18: roundToTwo(item.taxable_amount_18),
        taxable_amount_12: roundToTwo(item.taxable_amount_12),
        taxable_amount_5: roundToTwo(item.taxable_amount_5),
        cgst_9: roundToTwo(item.cgst_9),
        sgst_9: roundToTwo(item.sgst_9),
        cgst_6: roundToTwo(item.cgst_6),
        sgst_6: roundToTwo(item.sgst_6),
        cgst_2_5: roundToTwo(item.cgst_2_5),
        sgst_2_5: roundToTwo(item.sgst_2_5),
        igst_18: roundToTwo(item.igst_18),
        igst_12: roundToTwo(item.igst_12),
        igst_5: roundToTwo(item.igst_5),
        payment_method: item.payment_method,
        location_name: item.location_name,
      }));

      setData(groupedArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const [startDate, endDate] = reduxDateRange;
    setDates(reduxDateRange); 
    fetchData(startDate, endDate);
  }, [reduxDateRange]);

  const handleDateChange = (values) => {
    if (values && values.length === 2) {
      dispatch(setDateRange(values)); 
      setDates(values);
    } else {
      dispatch(setDateRange([null, null]));
      setDates([null, null]);
    }
  };

  const exportToExcel = () => {
    const formattedData = data.map((item) => ({
      ...item,
      payment_method: item.payment_method,
      location_name: item.location_name,
      total_amount: parseFloat(item.total_amount || 0).toFixed(2),
      taxable_amount_0:parseFloat(item.taxable_amount_0).toFixed(2),
      taxable_amount_18: parseFloat(item.taxable_amount_18 || 0).toFixed(2),
      taxable_amount_12: parseFloat(item.taxable_amount_12 || 0).toFixed(2),
      taxable_amount_5: parseFloat(item.taxable_amount_5 || 0).toFixed(2),
      cgst_9: parseFloat(item.cgst_9 || 0).toFixed(2),
      sgst_9: parseFloat(item.sgst_9 || 0).toFixed(2),
      cgst_6: parseFloat(item.cgst_6 || 0).toFixed(2),
      sgst_6: parseFloat(item.sgst_6 || 0).toFixed(2),
      cgst_2_5: parseFloat(item.cgst_2_5 || 0).toFixed(2),
      sgst_2_5: parseFloat(item.sgst_2_5 || 0).toFixed(2),
      igst_18: parseFloat(item.igst_18 || 0).toFixed(2),
      igst_12: parseFloat(item.igst_12 || 0).toFixed(2),
      igst_5: parseFloat(item.igst_5 || 0).toFixed(2),
  
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "GST Sales Report");
    XLSX.writeFile(workbook, "GST_Sales_B2B_Report.xlsx");
  };

  const getDynamicColumns = (data) => {
    const dynamicColumns = [];
    const taxKeys = new Set();

    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key.includes("igst")) {
          taxKeys.add(key);
        }
      });
    });

    // Generate dynamic column definitions
    taxKeys.forEach((key) => {
      dynamicColumns.push({
        title: key.replace(/_/g, " ").toUpperCase(),
        dataIndex: key,
        key,
      });
    });

    return dynamicColumns;
  };

  const baseColumns = [
    { title: "Invoice No", dataIndex: "invoice_no", key: "invoice_no" },
    {
      title: "Invoice Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
    },
    { title: "Customer", key: "customer", dataIndex: "customer" },
    { title: "GSTIN", dataIndex: "gstin", key: "gstin" },
    { title: "Payment Method", dataIndex: "payment_method", key: "payment_method" },
    { title: "Location", dataIndex: "location_name", key: "location_name" },
    {
      title: "Total Invoice Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Taxable Amount 0%",
      dataIndex: "taxable_amount_0",
      key: "taxable_amount_0",
    },
    {
      title: "Taxable Amount 18%",
      dataIndex: "taxable_amount_18",
      key: "taxable_amount_18",
    },
    {
      title: "Taxable Amount 12%",
      dataIndex: "taxable_amount_12",
      key: "taxable_amount_12",
    },
    {
      title: "Taxable Amount 5%",
      dataIndex: "taxable_amount_5",
      key: "taxable_amount_5",
    },
    { title: "CGST 9%", dataIndex: "cgst_9", key: "cgst_9" },
    { title: "SGST 9%", dataIndex: "sgst_9", key: "sgst_9" },
    { title: "CGST 6%", dataIndex: "cgst_6", key: "cgst_6" },
    { title: "SGST 6%", dataIndex: "sgst_6", key: "sgst_6" },
    { title: "CGST 2.5%", dataIndex: "cgst_2_5", key: "cgst_2_5" },
    { title: "SGST 2.5%", dataIndex: "sgst_2_5", key: "sgst_2_5" },
  
  ];

  const columns = [...baseColumns, ...getDynamicColumns(data)];


  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} className="navbar" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <RangePicker
          value={dates}
          onChange={handleDateChange}
          format="YYYY-MM-DD"
        />
        <Button
          type="primary"
          onClick={exportToExcel}
          style={{ marginLeft: 16 }}
        >
          Export to Excel
        </Button>
        <Select
          defaultValue={10}
          style={{ marginLeft: 16, width: 120 }}
          onChange={(value) => setPageSize(value)}
        >
          <Option value={10}>10</Option>
          <Option value={20}>20</Option>
          <Option value={50}>50</Option>
          <Option value={100}>100</Option>
          <Option value={500}>500</Option>
          <Option value={1000}>1000</Option>
        </Select>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <Table
          columns={columns}
         
          dataSource={data}
          rowKey="key"
          pagination={{ pageSize }}
        />
      )}
    </>
  );
};

export default GstSalesReport;
