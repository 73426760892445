import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  dateRange: [dayjs().startOf("month"), dayjs().endOf("month")],
};

const purchaseReportSlice = createSlice({
  name: "purchaseReport",
  initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
  },
});

export const { setDateRange } = purchaseReportSlice.actions;
export default purchaseReportSlice.reducer;
