import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  dateRange: [dayjs().startOf("month"), dayjs().endOf("month")], 
};

const b2cSalesSlice = createSlice({
  name: "b2cSales",
  initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
  },
});

export const { setDateRange } = b2cSalesSlice.actions;
export default b2cSalesSlice.reducer;
