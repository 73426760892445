import React, { useState, useEffect } from "react";
import { Modal, Typography, Table, Button, Row, Col, Spin } from "antd";
import { format } from "date-fns";
import numToWords from "num-to-words";
import "../css/Invoice.css";
import logo from "../images/invoice1.jpg";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  fetchInvoiceSettings,
  fetchPaymentAccounts,
  fetchBusinessLocations,
  fetchProductName,
  fetchTaxById,
} from "../api/api";

const { Title, Text } = Typography;

const Invoice = ({ selectedBooking, visible, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [invoiceSettings, setInvoiceSettings] = useState(null);
  const [paymentAccounts, setPaymentAccounts] = useState(null);
  const [businessLocations, setBusinessLocations] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxData, setTaxData] = useState([]);

  const formatDate = (dateString, formatString = "dd/MM/yyyy") => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, formatString) : "N/A";
  };

  useEffect(() => {
    const fetchSettingsAndLocations = async () => {
      try {
        const locations = await fetchBusinessLocations();
        setBusinessLocations(locations);

        const settings = await fetchInvoiceSettings();
        setInvoiceSettings(settings[0] ?? []);

        if (settings[0]?.invoice_bank_id) {
          const accounts = await fetchPaymentAccounts(
            settings[0].invoice_bank_id
          );
          const parsedBankDetails = parseBankDetails(accounts[0]?.note);
          setPaymentAccounts(parsedBankDetails);
        }


      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchSettingsAndLocations();
  }, [selectedBooking]);

  const locationDetails = businessLocations?.reduce((acc, location) => {
    acc[location?.id] = {
      address: `${location.landmark}, ${location.city}, ${location.state}, ${location.country}, ${location.zip_code}`,
      contact: `${location.mobile ? location.mobile : "N/A"}`,
    };
    return acc;
  }, {});

  const generateAndPrintPDF = async () => {
    const invoiceElement = document.querySelector(".invoice-a4");
    if (!invoiceElement) return;

    const padding = 10; // Padding in pixels (top and bottom)

    try {
      const canvas = await html2canvas(invoiceElement, {
        scale: 2, // For better quality
        useCORS: true, // Handle cross-origin images
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // Dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Scale to match PDF width
      const ratio = pdfWidth / canvasWidth;
      const imgHeightInPDF = canvasHeight * ratio;

      // Adjust height to account for padding
      const usableHeight = pdfHeight - 2 * padding;
      const totalPages = Math.ceil(imgHeightInPDF / usableHeight);

      for (let i = 0; i < totalPages; i++) {
        const sourceY = i * (usableHeight / ratio); // Start point on the canvas
        const pageCanvas = document.createElement("canvas");
        const context = pageCanvas.getContext("2d");

        // Set canvas size for the single page
        pageCanvas.width = canvasWidth;
        pageCanvas.height = usableHeight / ratio;

        // Copy the specific portion of the main canvas
        context.drawImage(
          canvas,
          0,
          sourceY,
          canvasWidth,
          usableHeight / ratio,
          0,
          0,
          canvasWidth,
          usableHeight / ratio
        );

        // Convert the portion to an image
        const pageData = pageCanvas.toDataURL("image/png");

        // Add the page image to the PDF with padding
        if (i > 0) pdf.addPage(); // Add a new page after the first
        pdf.addImage(pageData, "PNG", 0, padding, pdfWidth, usableHeight); // Adjust for top padding
      }

      // Trigger the print dialog
      pdf.autoPrint(); // Automatically trigger the print dialog
      window.open(pdf.output("bloburl"), "_blank"); // Open in a new tab for printing
    } catch (error) {
      console.error("Error generating PDF with padding", error);
    }
  };

  // const generateAndPrintPDF = async () => {
  //   const invoiceElement = document.querySelector(".invoice-a4");
  //   if (!invoiceElement) return;

  //   const padding = 20; // Padding in pixels (top and bottom)

  //   try {
  //     const canvas = await html2canvas(invoiceElement, {
  //       scale: 2, // For better quality
  //       useCORS: true, // Handle cross-origin images
  //     });

  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");

  //     // Dimensions
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const canvasWidth = canvas.width;
  //     const canvasHeight = canvas.height;

  //     // Scale to match PDF width
  //     const ratio = pdfWidth / canvasWidth;
  //     const imgHeightInPDF = canvasHeight * ratio;

  //     // Adjust height to account for padding
  //     const usableHeight = pdfHeight - 2 * padding;
  //     const totalPages = Math.ceil(imgHeightInPDF / usableHeight);

  //     for (let i = 0; i < totalPages; i++) {
  //       const sourceY = i * (usableHeight / ratio); // Start point on the canvas
  //       const pageCanvas = document.createElement("canvas");
  //       const context = pageCanvas.getContext("2d");

  //       // Set canvas size for the single page
  //       pageCanvas.width = canvasWidth;
  //       pageCanvas.height = usableHeight / ratio;

  //       // Copy the specific portion of the main canvas
  //       context.drawImage(
  //         canvas,
  //         0,
  //         sourceY,
  //         canvasWidth,
  //         usableHeight / ratio,
  //         0,
  //         0,
  //         canvasWidth,
  //         usableHeight / ratio
  //       );

  //       // Convert the portion to an image
  //       const pageData = pageCanvas.toDataURL("image/png");

  //       // Add the page image to the PDF with padding
  //       if (i > 0) pdf.addPage(); // Add a new page after the first
  //       pdf.addImage(pageData, "PNG", 0, padding, pdfWidth, usableHeight); // Adjust for top padding
  //     }

  //     // Embed the PDF into a temporary iframe and trigger print
  //     const blobURL = URL.createObjectURL(pdf.output("blob"));
  //     const iframe = document.createElement("iframe");

  //     iframe.style.position = "fixed";
  //     iframe.style.right = "0";
  //     iframe.style.bottom = "0";
  //     iframe.style.width = "1px";
  //     iframe.style.height = "1px";
  //     iframe.style.border = "none";
  //     iframe.src = blobURL;
  //     document.body.appendChild(iframe);

  //     // Trigger print when the iframe is loaded
  //     iframe.onload = () => {
  //       iframe.contentWindow?.focus();
  //       iframe.contentWindow?.print();

  //       // Wait for the print dialog to close before removing the iframe
  //       iframe.contentWindow?.addEventListener("afterprint", () => {
  //         // After printing is done, remove the iframe
  //         document.body.removeChild(iframe);
  //       });
  //     };
  //   } catch (error) {
  //     console.error("Error generating and printing PDF", error);
  //   }
  // };

  const parseBankDetails = (note) => {
    if (!note) return {};

    const bankDetails = {};
    const lines = note.split("\r\n");

    lines.forEach((line) => {
      line = line.trim();

      if (line.includes("Name:")) {
        bankDetails.accountName = line
          .replace("Name:", "")
          .replace(",", "")
          .trim();
      }
      if (line.includes("Bank:")) {
        bankDetails.bankName = line
          .replace("Bank:", "")
          .replace(",", "")
          .trim();
      }
      if (line.includes("A/c No:")) {
        bankDetails.accountNumber = line
          .replace("A/c No:", "")
          .replace(",", "")
          .trim();
      }
      if (line.includes("Branch & IFSC:")) {
        const branchAndIfsc = line.replace("Branch & IFSC:", "").trim();
        const [branch, ifsc] = branchAndIfsc.split(" & ");
        bankDetails.branchName = branch?.trim();
        bankDetails.ifscCode = ifsc?.trim();
      }
    });

    return bankDetails;
  };

  const convertAmountToWords = (amount) => {
    const numericAmount = parseFloat(amount || 0);
    return numToWords(numericAmount).toUpperCase();
  };

  const itemColumns = [
    { title: "S.No", dataIndex: "sno", key: "sno", align: "center", width: 70 },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 230,
    },
    { title: "Tax", dataIndex: "gst", key: "gst", align: "center", width: 90 },
    { title: "Qty", dataIndex: "qty", key: "qty", align: "center", width: 90 },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      align: "center",
      width: 120,
    },
    {
      title: "Amount (Rs)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 170,
    },
  ];

  const taxColumns = [
    { title: "Tax", dataIndex: "tax", key: "tax", align: "center" },
    {
      title: "Taxable Amt.",
      dataIndex: "taxable_amt",
      key: "taxable_amt",
      align: "center",
    },
    { title: "SGST", dataIndex: "sgst", key: "sgst", align: "center" },
    { title: "CGST", dataIndex: "cgst", key: "cgst", align: "center" },
    { title: "IGST", dataIndex: "igst", key: "igst", align: "center" },
  ];

  useEffect(() => {
    if (selectedBooking?.sell_lines) {
      const fetchProductAndTaxDetails = async () => {
        setLoading(true);
        try {
          const productDetails = await Promise.all(
            selectedBooking.sell_lines.map(async (line, index) => {
              const productName = await fetchProductName(line.product_id);
              const taxPercent = await fetchTaxById(line?.tax_id);
              const itemTax = parseFloat(line?.item_tax || 0);
              const quantity = parseFloat(line?.quantity || 1);
              const unitPrice = parseFloat(line?.unit_price || 0);

              return {
                sno: index + 1,
                item_name: productName,
                gst: taxPercent?.name || "N/A",
                qty: quantity,
                rate: unitPrice,
                amount: (unitPrice * quantity).toFixed(2),
                taxAmount: (itemTax * quantity).toFixed(2),
                tax_id: line?.tax_id,
                tax_rate: parseFloat(taxPercent?.rate || 0),
              };
            })
          );

          setItems(productDetails);

          // Calculate Subtotal
          const totalAmount = productDetails.reduce(
            (acc, item) => acc + parseFloat(item.amount),
            0
          );
          setSubTotal(totalAmount.toFixed(2));

          // Group taxes dynamically
          const taxGroups = productDetails.reduce((acc, item) => {
            if (!item.tax_id) return acc; // Skip if no tax_id

            const taxableAmt = parseFloat(item.amount);
            const taxAmount = parseFloat(item.taxAmount || 0);
            const isIGST = item.gst.toLowerCase().includes("igst");

            if (!acc[item.tax_id]) {
              acc[item.tax_id] = {
                tax: item.gst,
                taxable_amt: 0,
                sgst: 0,
                cgst: 0,
                igst: 0,
              };
            }

            acc[item.tax_id].taxable_amt += taxableAmt;

            if (isIGST) {
              // All tax amount goes to IGST
              acc[item.tax_id].igst += taxAmount;
            } else {
              // Split equally between SGST and CGST
              const splitAmount = taxAmount / 2;
              acc[item.tax_id].sgst += splitAmount;
              acc[item.tax_id].cgst += splitAmount;
            }

            return acc;
          }, {});

          // Convert grouped taxes to an array
          const dynamicTaxData = Object.values(taxGroups).map((group) => ({
            tax: group.tax,
            taxable_amt: group.taxable_amt.toFixed(2),
            sgst: group.sgst.toFixed(2),
            cgst: group.cgst.toFixed(2),
            igst: group.igst.toFixed(2),
          }));

          setTaxData(dynamicTaxData);

          // Calculate total tax amount
          const totalTaxAmount = dynamicTaxData.reduce(
            (acc, group) =>
              acc +
              parseFloat(group.sgst) +
              parseFloat(group.cgst) +
              parseFloat(group.igst),
            0
          );
          setTaxAmount(totalTaxAmount.toFixed(2));
        } catch (error) {
          console.error("Error fetching product and tax details", error);
          setItems([]);
          setSubTotal(0);
          setTaxAmount(0);
          setTaxData([]);
        } finally {
          setLoading(false);
        }
      };

      fetchProductAndTaxDetails();
    }
  }, [selectedBooking]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="print"
          onClick={generateAndPrintPDF}
          type="primary"
          style={{ marginRight: 8 }}
        >
          Print Invoice
        </Button>,
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={1100}
      className="invoice-modal"
    >
      <div className="invoice-a4">
        <Typography>
          {/* Header Section */}
          <div className="invoice-header">
            <Row>
              <Col span={16}>
                <img
                  src={logo}
                  alt="Invoice Logo"
                  style={{ width: "300px", marginBottom: "10px" }}
                />
                <br />
                <Text strong>Address: </Text>
                {locationDetails[selectedBooking?.location_id]?.address ||
                  "N/A"}
                <br />
                <Text strong>Contact: </Text>
                {locationDetails[selectedBooking?.location_id]?.contact ||
                  "N/A"}
                {",  support@rkfarmstore.com"}
                <br />
                <Text strong>Website:</Text> {"www.rkfarmstore.com"} <br />
                <Text strong>GSTIN:</Text> NA
              </Col>
              <Col span={8} style={{ textAlign: "right", marginTop: "20px" }}>
                <Text strong>Invoice No:</Text>{" "}
                {selectedBooking?.invoice_no || ""} <br />
                <Text strong>Date:</Text>{" "}
                {formatDate(selectedBooking?.transaction_date || "")} <br />
                <Text strong>Customer Reference:</Text>{" "}
                {selectedBooking?.contact?.contact_id || "N/A"} <br />
              </Col>
            </Row>
            {/* Customer Details */}
            <Row>
              <Col span={12}>
                <Title level={4}>Bill To</Title>
                <Text strong>Name:</Text>{" "}
                {selectedBooking?.contact?.name || "N/A"} <br />
                <Text strong>Address:</Text>{" "}
                {selectedBooking?.contact?.address_line_1 || "N/A"} <br />
                <Text strong>Mobile:</Text>{" "}
                {selectedBooking?.contact?.mobile || "N/A"} <br />
              </Col>
              <Col span={12}>
                <Title level={4}>Ship To</Title>
                <Text strong>Name:</Text>{" "}
                {selectedBooking?.contact?.name || "N/A"} <br />
                <Text strong>Address:</Text>{" "}
                {selectedBooking?.contact?.shipping_address || "N/A"} <br />
              </Col>
            </Row>
          </div>
          {/* Item Details */}
          <div className="invoice-body">
            <Title level={4}>Item Details</Title>
            {loading ? (
              <Spin />
            ) : (
              <Table
                className="invoice-table"
                columns={itemColumns}
                dataSource={items}
                pagination={false}
                rowKey="sno"
                bordered
                summary={() => (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} align="right">
                        <Text strong>Sub Total:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        {subTotal || "0.00"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} align="right">
                        <Text strong>Shipping Charges:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        {selectedBooking?.shipping_charges
                          ? parseFloat(selectedBooking.shipping_charges).toFixed(
                            2
                          )
                          : "0.00"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} align="right">
                        <Text strong>Discount:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        {selectedBooking?.discount_amount
                          ? parseFloat(selectedBooking.discount_amount).toFixed(
                            2
                          )
                          : "0.00"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} align="right">
                        <Text strong>Tax Amount:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        {taxAmount || "0.00"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={5} align="right">
                        <Text strong>Grand Total:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell align="center">
                        {selectedBooking?.final_total
                          ? parseFloat(selectedBooking.final_total).toFixed(2)
                          : "0.00"}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell colSpan={6}>
                        <Text strong>Amount in Words:</Text>{" "}
                        {convertAmountToWords(
                          selectedBooking?.final_total || "0.00"
                        )}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )}
              />
            )}
            {/* Tax Details on Right */}
            <Row>
              <Col span={14}>
                <Title level={4} style={{ marginTop: "28px" }}>
                  Payment Methods
                </Title>
                <Text style={{ fontSize: "15px" }}>
                  {selectedBooking?.payment_lines?.map((payment, index) => (
                    <div key={index}>

                      {
                        payment.is_return === 1
                          ? `${parseFloat(payment.amount).toFixed(2)} (Change Return)`
                          : payment.method === "bank_transfer"
                            ? `Bank transfer : ${parseFloat(payment.amount).toFixed(2)}`
                            : payment.method === `cash` ? `Cash : ${parseFloat(payment.amount).toFixed(2)}`:`${payment.method} : ${parseFloat(payment.amount).toFixed(2)}`
                      }
                    </div>
                  ))}
                </Text>
                <Title
                  level={4}
                  style={{ marginTop: "28px" }}
                >
                  Bank Details
                </Title>
                <Text style={{ fontSize: "15px" }}>
                  A/c Name: {paymentAccounts?.accountName || "N/A"} {" | "}
                  A/c No: {paymentAccounts?.accountNumber || "N/A"} <br />
                  Bank: {paymentAccounts?.bankName || "N/A"} {" | "}
                  Branch & IFSC: {paymentAccounts?.branchName || "N/A"} &{" "}
                  {paymentAccounts?.ifscCode || "N/A"}
                </Text>

                <Title level={4} style={{ fontSize: "12px" }}>
                  Terms & Conditions
                </Title>
                <Text style={{ fontSize: "10px" }}>
                  1. Our responsibility ends at delivery to
                  Customer/transporter. <br />
                  2. Interest @ 18% p.a. will be charged if the payment is not
                  made within the stipulated time. <br />
                  3. Subject to Coimbatore Jurisdiction only.
                </Text>
                <Title level={4} style={{ fontSize: "12px" }}>
                  Note:
                </Title>
                <Text style={{ fontSize: "10px" }}>
                  {selectedBooking?.additional_notes}
                </Text>
              </Col>

              <Col span={10} style={{ textAlign: "left" }}>
                <Title level={4}>Tax Details</Title>
                <Table
                  columns={taxColumns}
                  dataSource={taxData}
                  pagination={false}
                  rowKey="tax"
                  bordered
                  className="invoice-table"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "20px", textAlign: "right" }}>
              <Col span={24}>
                <Text strong>For RK Farm Store</Text>
                <br />
                <br />
                <br />
                <Text>(Signature)</Text>
              </Col>
            </Row>
          </div>
        </Typography>
      </div>
    </Modal>
  );
};

export default Invoice;
